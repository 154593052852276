import { useSearch } from '@contexts/search'
import { availabilityService } from '@service/availability'
import { useMutation, useQuery } from 'react-query'
import { useRouter } from 'next/router'
import { CityCode, DateTimeSelectorTypes } from '@util/enums'
import { format, minutesToMilliseconds, setHours, setMinutes } from 'date-fns'
import { useLocaleConfig } from '@contexts/config'
import { useCallback, useMemo } from 'react'
import { convertTimeSlotToPickerTime, isWithin24Hours } from './utils'
import { PickerTime } from 'src/types/Time'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from '@contexts/global'
import { ProductType } from '@service/configuration.types'

const queryDateFormat = 'yyyy-MM-dd'

export const useTimeSelector = (specTime: DateTimeSelectorTypes, cityCode?: CityCode) => {
    const { t } = useTranslation()
    const {
        query: { listingsId, bookingId },
    } = useRouter()
    const { citySettings } = useLocaleConfig()
    const { fromDate, toDate, fromPickerTime } = useSearch()

    const city = useMemo(
        () => cityCode || (citySettings?.cityCode as CityCode) || CityCode.DXB,
        [cityCode, citySettings?.cityCode],
    )

    const selectedDate = useMemo(
        () => (specTime === DateTimeSelectorTypes.from ? fromDate : toDate),
        [fromDate, specTime, toDate],
    )

    const queryKey = useMemo(
        () =>
            listingsId
                ? ['getTimeSlotsByListingId', listingsId, selectedDate]
                : ['getTimeSlotsByBookingId', bookingId, selectedDate],
        [bookingId, listingsId, selectedDate],
    )

    const FromDateTime = useMemo(() => {
        if (fromDate && fromPickerTime)
            return setHours(
                setMinutes(fromDate, fromPickerTime.startDate.getMinutes()),
                fromPickerTime.startDate.getHours(),
            )

        return null
    }, [fromDate, fromPickerTime])

    const getToDateTime = useCallback(
        (date: Date) => {
            if (toDate && date) return setHours(setMinutes(toDate, date.getMinutes()), date.getHours())

            return null
        },
        [toDate],
    )

    const { mutateAsync: getTimeSlotsByListingIdMutate } = useMutation({
        mutationFn: (date: Date) =>
            availabilityService.getTimeSlotsByListingId(
                listingsId as string,
                encodeURIComponent(format(date, queryDateFormat)),
                city,
            ),
    })

    const { mutateAsync: getTimeSlotsByBookingIdMutate } = useMutation({
        mutationFn: (date: Date) =>
            availabilityService.getTimeSlotsByBookingId(
                bookingId as string,
                encodeURIComponent(format(date, queryDateFormat)),
                city,
            ),
    })

    const fetchData = useMemo(() => {
        if (!selectedDate) return () => null
        return listingsId
            ? () => getTimeSlotsByListingIdMutate(selectedDate)
            : bookingId
            ? () => getTimeSlotsByBookingIdMutate(selectedDate)
            : () => null
    }, [selectedDate, listingsId, bookingId, getTimeSlotsByListingIdMutate, getTimeSlotsByBookingIdMutate])

    const timeSlotsQuery = useQuery(queryKey, fetchData, {
        staleTime: minutesToMilliseconds(1),
        enabled: !!fetchData && (!!listingsId || !!bookingId),
    })

    const getTimeSlotList = useMemo(
        () => convertTimeSlotToPickerTime(timeSlotsQuery.data?.data?.timeSlotAvailabilities || []),
        [timeSlotsQuery.data?.data?.timeSlotAvailabilities],
    )

    const {
        bookingState: { productType },
    } = useGlobalState()

    const isMonthly = productType === ProductType.MONTHLY

    const disabledWithin24HoursSlots = useCallback(
        (timeSlotList: PickerTime[]) => {
            return timeSlotList.map((slot) => {
                const from = FromDateTime
                const to = getToDateTime(slot.startDate)
                const copySlot = { ...slot }

                if (!slot.isEnabled) {
                    // If the timeslot is blocked by the supplier, we set the correct text.
                    copySlot.i18n = t('timeSlotBlockedBySupplierText', 'This timeslot is blocked by the supplier.')
                }

                // !isMonthly is Added because it was disabling timeslot for Monthly case as well and we need this for Daily only
                if (from && to && slot.isEnabled && !isMonthly) {
                    // If the timeslot isn't blocked by the supplier, we need to check the 24-hour rule.
                    const isWithin24HoursValue = isWithin24Hours(from, to)
                    copySlot.isEnabled = !isWithin24HoursValue
                    if (isWithin24HoursValue) {
                        copySlot.i18n = t('timeSlotBlockedByLess24Hours', 'Booking length cannot be less than 24 hours')
                    }
                }

                return copySlot
            })
        },
        [FromDateTime, getToDateTime, isMonthly, t],
    )
    const checkSingleTimeSlotEnabled = (slot: PickerTime, timeSlots: PickerTime[]): boolean => {
        return timeSlots.some(
            (existingSlot) =>
                existingSlot.start === slot.start && existingSlot.end === slot.end && existingSlot.isEnabled,
        )
    }

    return {
        timeSlotsQuery,
        convertTimeSlotToPickerTime,
        timeSlotList: getTimeSlotList,
        timeSlotListFiltering: disabledWithin24HoursSlots,
        checkSingleTimeSlotEnabled: checkSingleTimeSlotEnabled,
    }
}
